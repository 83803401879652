export const LegalLinks = [
  {
    "alt": "Cookie Policy",
    "url": "/cookies",
  },
  {
    "alt": "Privacy",
    "url": "/privacy",
  },
]
