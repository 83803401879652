import React, { useContext } from 'react';
import styled from 'styled-components';
import { useCountdown } from '../../hooks/useCountdown';
import IconRocket from '../../assets/rocket.svg';
import IconRocketLaunched from '../../assets/rocket-launched.svg';
import { FaLongArrowAltRight } from 'react-icons/fa';
import FontSizeContext from '../../context/FontSizeContext';

const CountdownTimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${({ units }) => (units.xlarge)};
  color: black;
  width: 100%;
  height: 100%;
`;

const CountdownTimerImage = styled.img`
  max-height: 100%;
  width: auto;
  padding: 0 5%;
`;

const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: calc(1.5 * ${({ units }) => (units.em)});
  padding: 0 calc(0.75 * ${({ units }) => (units.em)}) 0 calc(0.75 * ${({ units }) => (units.em)});
  color: ${({ changeColor }) => (changeColor ? "red" : "none")};
`;

const CounterArrow = styled(FaLongArrowAltRight)`
  font-size: calc(5 * ${({ units }) => (units.em)});
`

const ExpiredNoticeContainer = styled(CountdownTimerContainer)`
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
`;

const ExpiredNoticeHeading = styled.span`
  font-weight: bold;
  color: red;
`

const CounterType = styled.span`
  text-transform: uppercase;
  font-size: ${({ units }) => (units.large)};
  line-height: calc(2 * ${({ units }) => (units.em)});
`;

const DateTimeDisplay = ({ value, type, isDanger, units }) => {
  return (
    <CounterContainer changeColor={isDanger} units={units}>
      <p>{value}</p>
      <CounterType units={units}>{type}</CounterType>
    </CounterContainer>
  );
};

const ExpiredNotice = ({ targetDate, units }) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return (
    <CountdownTimerContainer units={units}>
      <CountdownTimerImage src={IconRocketLaunched} alt="rocket-launched" />
      <ExpiredNoticeContainer units={units}>
        <ExpiredNoticeHeading>Available since</ExpiredNoticeHeading>
        <p>{targetDate.getDate()} {months[targetDate.getMonth()]} {targetDate.getFullYear()}</p>
      </ExpiredNoticeContainer>
    </CountdownTimerContainer>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, units }) => {
  return (
    <CountdownTimerContainer units={units}>
      <CountdownTimerImage src={IconRocket} alt="rocket" />
      <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 5} units={units} />
      <p>:</p>
      <DateTimeDisplay value={hours} type={'Hours'} isDanger={days === 0 && hours <= 24} units={units} />
      <p>:</p>
      <DateTimeDisplay value={minutes} type={'Mins'} isDanger={days === 0 && hours === 0 && minutes <= 60} units={units} />
      <p>:</p>
      <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={days === 0 && hours === 0 && minutes === 0 && seconds <= 60} units={units} />
    </CountdownTimerContainer>
  );
};

export default ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const fontSize = useContext(FontSizeContext);

  if (isNaN(days + hours + minutes + seconds)) {
    return (
      <CountdownTimerContainer units={fontSize}>
        <CountdownTimerImage src={IconRocket} alt="rocket" />
        <CounterArrow color="red" units={fontSize} />
        <CountdownTimerImage src={IconRocketLaunched} alt="rocket-launched" />
      </CountdownTimerContainer>
    );
  }

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice targetDate={targetDate} units={fontSize} />;
  }
  else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        units={fontSize}
      />
    );
  }
};
