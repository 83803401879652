import React from "react";
import {
  FaInstagram as InstagramIcon,
  FaYoutube as YoutubeIcon,
  FaXTwitter as TwitterIcon,
  FaLinkedinIn as LinkedinIcon,
} from "react-icons/fa6";

export const SocialMediaData = [
  {
    logo: <LinkedinIcon />,
    alt: "LinkedIn",
    url: "https://in.linkedin.com/company/agmah"
  },
  {
    logo: <YoutubeIcon />,
    alt: "Youtube",
    url: "https://www.youtube.com/@agmah"
  },
  {
    logo: <TwitterIcon />,
    alt: "Twitter",
    url: "https://twitter.com/AgmahAI"
  },
  {
    logo: <InstagramIcon />,
    alt: "Instagram",
    url: "https://instagram.com/agmah.ai"
  },
];
