import styled from 'styled-components';

export const LegalPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : "space-around")};
  align-items: ${({ align }) => (align ? align : "none")};
  width: 80%;
  font-family: 'Roboto';
  font-size: ${({ units }) => (units.p) };
  margin: 5vmax 0;
  text-align: left;
`;

export const LegalPolicyHeading = styled.h1`
  font-family: 'Montserrat';
  font-size: ${({ units }) => (units.h1) };
  text-align: center;
  margin-bottom: 0.5em;
`;

export const LegalPolicySubHeading = styled.h2`
  font-family: 'Montserrat';
  font-size: ${({ units }) => (units.h2) };
  margin-bottom: 1em;
`;

export const LegalPolicySectionHeading = styled.h3`
  font-family: 'Montserrat';
  font-size: ${({ units }) => (units.h3) };
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const LegalPolicySubSectionHeading = styled.h4`
  font-family: 'Montserrat';
  font-size: ${({ units }) => (units.h4) };
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const LegalPolicyOrderedList = styled.ol`
  margin: 1em 0;
  margin-left: 3em;
`;

export const LegalPolicyListElement = styled.li`
  margin: 0.5em 0;
`;

export const LegalPolicyBold = styled.b`
  font-family: 'Roboto';
  font-weight: 500;
`;

export const LegalContactUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  background-color: #dfeafe;
  width: 100%;
  padding: 5vmax 0;
  gap: 5vmax;
  font-size: ${({ units }) => (units.xlarge) };
  text-align: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
    gap: 2.5vmax;
  }

`;
