import React from "react";
import {
  FooterContainer,
  FooterIconsWrapper,
  FooterIcon,
  FooterButtonsWrapper,
  FooterButton,
  FooterText,
  FooterButtonOuter,
} from "./Footer.elements";
import { SocialMediaData } from "../../data/SocialMedia.data";
import { LegalLinks } from "../../data/LegalLinks.data";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <FooterContainer>
        <FooterIconsWrapper>
          {SocialMediaData.map((item, index) => {
            return (
              <FooterIcon key={`socialmedia_${index}`} href={item.url} target="_blank" aria-label={item.alt}>
                {item.logo}
              </FooterIcon>
            );
          })}
        </FooterIconsWrapper>

        <FooterButtonsWrapper>
          {LegalLinks.map((item, index, arr) => {
            return (
              <FooterButtonOuter key={`legallinks_${index}`} $end={index + 1 === arr.length}>
                <FooterButton to={item.url}>
                  {item.alt}
                </FooterButton>
              </FooterButtonOuter>
            );
          })}
        </FooterButtonsWrapper>

        <FooterText>Copyright © 2021-{currentYear} AGMAH Holdings. All rights reserved</FooterText>
      </FooterContainer>
    </>
  );
}

export default Footer;
