import styled from "styled-components";
import { Link } from "gatsby";

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #061551;
  padding: 2em;
`;

export const FooterIconsWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

export const FooterIcon = styled.a`
  font-size: 1.8rem;
  color: #ffffff;
  margin: 0.4em 1em;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 1.5rem;
  }

  @media (max-width: 576px) {
    font-size: 1.3rem;
  }
`;

export const FooterButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0.5em 0;
`;

export const FooterButtonOuter = styled.div`
  border: none;
  border-right: ${({ $end }) => ($end ? "none" : "1px solid #ffffffa6")};
  padding: 0 2em;
`;

export const FooterButton = styled(Link)`
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5rem;
  width: fit-content;

  @media (max-width: 1200px) {
    font-size: 1.4rem;
  }

  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
`;

export const FooterText = styled.p`
  font-size: 1.6rem;
  color: #ffffffa6;
  margin: 0.5em;

  @media (max-width: 1200px) {
    font-size: 1.4rem;
    margin: 0.4em;
  }

  @media (max-width: 576px) {
    font-size: 1.2rem;
  }

  @media (max-width: 375px) {
    font-size: 1.1rem;
  }

  @media (max-width: 320px) {
    font-size: 1rem;
  }
`;
