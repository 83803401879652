import React from "react";
import { NavBar, Footer } from ".";
import GlobalStyle from "../globalStyles";

export default ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <NavBar />
      <main>{children}</main>
      <Footer />
    </>
  );
}
