import React from "react";
import { useFontSize } from "../hooks/useFontSize";

const FontSizeContext = React.createContext(null);

const FontSizeProvider = ({ children }) => {
  const fontSize = useFontSize();

  return (
    <FontSizeContext.Provider value={fontSize}>
      {children}
    </FontSizeContext.Provider>
  )

};

export default FontSizeContext;

export { FontSizeProvider };
