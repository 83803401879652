import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html{
    font-size:62.5%;
    font-family: roboto;
    scroll-behavior: smooth;
  }

  ::selection {
    background: gray;
    color:#ffffff;
  }
`;

export default GlobalStyle;

const buttonDefaultPrimary = "#0467FB";
const buttonDefaultSecondary = "#4B59F7";

export const AgmahGreenPrimary = "#4CAF50";
export const AgmahGreenSecondary = "#3E8E41";

export const AgmahOrangePrimary = "#F15E22";
export const AgmahOrangeSecondary = "#FFBDA1";

export const Button = styled.button`
  border: none;
  border-radius: ${({ radius }) => (radius ? radius : "0.5vmax")};
  background: ${({ primary, secondary }) => (primary && secondary ? primary : buttonDefaultPrimary)};
  padding: ${({ fontSize }) => (fontSize ? `calc(0.5 * ${fontSize}) ${fontSize}` : '0.5vmax 1vmax')};
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '0.75vmax')};
  font-family: 'Roboto';
  font-weight: 500;
  outline: none;
  border-style: solid;
  border-color: ${({ primary, secondary }) => (primary && secondary ? primary : buttonDefaultPrimary)};
  margin: ${({ margin }) => (margin ? margin : "none")};
  width: ${({ width }) => (width ? width : "none")};
  height: ${({ height }) => (height ? height : "none")};
  box-shadow: 0 calc(6 * ${({ units }) => (units.px)}) calc(8 * ${({ units }) => (units.px)}) 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    /* transition: all 0.3s ease-out; */
    background-color: ${({ primary, secondary }) => (primary && secondary ? secondary : buttonDefaultSecondary)};
    border-color: ${({ primary, secondary }) => (primary && secondary ? secondary : buttonDefaultSecondary)};
  }

  &:active {
    transform: translateY(calc(2 * ${({ units }) => (units.px)}));
    box-shadow: 0 calc(4 * ${({ units }) => (units.px)}) calc(8 * ${({ units }) => (units.px)}) 0 rgba(0, 0, 0, 0.2);
  }

  /* @media screen and (max-width: 960px) {
    width: 100%;
  } */
`;

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
