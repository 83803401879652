import React from "react";
import styled from "styled-components";

const AgmahSpan = styled.span`
  font-family: 'Montserrat';
  color: ${({ color }) => (color ? color : "none")};
`;

const AgmahRegistered = styled.sup`
  font-family: 'Roboto';
`;

export default ({ color, showReg = true }) => {
  return (
    <AgmahSpan color={color}>
      AGMAH{showReg && <AgmahRegistered>®</AgmahRegistered>}
    </AgmahSpan>
  );
}
