import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import {
  FaBars as MenuIcon,
  FaXmark as CloseIcon,
} from "react-icons/fa6";

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 8rem;
  width: 100%;
  padding: 1.2rem 5%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 5;
`;

const NavBarLogoImage = styled.img`
  height: 5.5em;

  @media (max-width: 1200px) {
    height: 5em;
  }
  @media (max-width: 992px) {
    height: 4.5em;
  }
  @media (max-width: 768px) {
    height: 4em;
  }
  @media (max-width: 576px) {
    height: 3.5em;
  }
`;

const NavBarItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  @media (max-width: 992px) {
    display: ${(props) => (props.$showNavBar ? "block" : "none")};
    position: absolute;
    top: 8rem;
    left: 0;
    width: 100%;
    padding: 0 5% 2%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 5px;
  }
`;

const NavBarItems = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 992px) {
    display: block;
  }
`;

const NavBarItem = styled.li`
  list-style: none;
  margin-left: 6em;

  @media (max-width: 1200px) {
    margin-left: 5em;
  }

  @media (max-width: 992px) {
    width: calc(100% + 2em);
    position: relative;
    left: -1em;
    padding: 1em;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

const NavLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  color: #151451;
  font-family: "Montserrat";

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const NavBarButtonCollapsable = styled.button`
  display: none;
  font-size: 2.7rem;
  color: #101c62;
  border: none;
  background: none;
  cursor: pointer;

  @media (max-width: 992px) {
    display: flex;
  }
  @media (max-width: 768px) {
    font-size: 2.3rem;
  }
`;

export default () => {
  const [showNavBar, setShowNavBar] = useState(false);
  const navBarOnClickHandle = () => { setShowNavBar(false); };

  return (
    <>
      <NavBarContainer>
        <Link onClick={navBarOnClickHandle} to="/"><NavBarLogoImage src={Logo} alt="logo" /></Link>

        <NavBarItemsContainer $showNavBar={showNavBar}>
          <NavBarItems>
            <NavBarItem>
              <NavLink onClick={navBarOnClickHandle} to="/">Home</NavLink>
            </NavBarItem>
            <NavBarItem>
              <NavLink onClick={navBarOnClickHandle} to="/#our-offerings">Our Offerings</NavLink>
            </NavBarItem>
            <NavBarItem>
              <NavLink onClick={navBarOnClickHandle} to="/about-us">About Us</NavLink>
            </NavBarItem>
            <NavBarItem>
              <NavLink onClick={navBarOnClickHandle} to="/contact-us">Contact Us</NavLink>
            </NavBarItem>
            <NavBarItem>
              <NavLink onClick={navBarOnClickHandle} to="/#request-demo">Request Demo</NavLink>
            </NavBarItem>
          </NavBarItems>
        </NavBarItemsContainer>

        <NavBarButtonCollapsable onClick={() => { setShowNavBar(!showNavBar); }}>
          {showNavBar ? <CloseIcon /> : <MenuIcon />}
        </NavBarButtonCollapsable>

      </NavBarContainer>
    </>
  );
};
