import React, { useContext } from "react";
import FontSizeContext from "../../context/FontSizeContext";
import { Button } from "../../globalStyles";
import { LegalContactUsContainer } from "./LegalPolicy.elements";

export default ({ blockText = "Got any questions or concerns about our policies?", buttonText = "Contact Legal" }) => {

  const fontSize = useContext(FontSizeContext);

  return (
    <>
      <LegalContactUsContainer units={fontSize}>
        {blockText}

        <Button units={fontSize} fontSize={fontSize.xlarge} radius="10vmax" onClick={() => { window.open("/contact-us", "_parent") }}>
          {buttonText}
        </Button>
      </LegalContactUsContainer>
    </>
  );
}
