import React from "react";
import { Layout } from "./src/components";
import { FontSizeProvider } from "./src/context/FontSizeContext";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <FontSizeProvider>
    {element}
  </FontSizeProvider>
);
