import { useState, useEffect } from "react";

const useFontSize = () => {

  const generateFontSizes = (scale = undefined) => {

    const factor = scale ? scale : 1;
    const unit = scale ? "vw" : "px";

    const fontSize = {
      xxsmall: `${9 * factor}${unit}`,   // 9px / 1920px
      xsmall: `${10 * factor}${unit}`,  // 10px / 1920px
      small: `${13 * factor}${unit}`,  // 13px / 1920px
      medium: `${16 * factor}${unit}`,  // 16px / 1920px
      large: `${18 * factor}${unit}`,
      xlarge: `${24 * factor}${unit}`,  // 24px / 1920px
      xxlarge: `${32 * factor}${unit}`,  // 32px / 1920px
      h1: `${32 * factor}${unit}`,  // 2em
      h2: `${24 * factor}${unit}`,  // 1.5em
      h3: `${20.8 * factor}${unit}`,  // 1.3em or 20.8px / 1920px
      h4: `${16 * factor}${unit}`,  // 1em
      h5: `${12.8 * factor}${unit}`,  // 0.8em or 12.8px / 1920px
      h6: `${11.2 * factor}${unit}`,  // 0.7em or 11.2px / 1920px
      em: `${16 * factor}${unit}`,  // 1em
      px: `${1 * factor}${unit}`,  // 1px
      p: `${16 * factor}${unit}`,  // 1em
    };

    return fontSize;
  }

  const [fontSize, setFontSize] = useState(generateFontSizes());

  useEffect(() => {
    const handleResize = () => {
      setFontSize(generateFontSizes(100 / window.outerWidth));
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return fontSize;
};

export { useFontSize };
